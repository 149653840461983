exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-tb-job-js": () => import("./../../../src/templates/TbJob.js" /* webpackChunkName: "component---src-templates-tb-job-js" */),
  "component---src-templates-tb-page-js": () => import("./../../../src/templates/TbPage.js" /* webpackChunkName: "component---src-templates-tb-page-js" */),
  "component---src-templates-tb-post-area-js": () => import("./../../../src/templates/TbPostArea.js" /* webpackChunkName: "component---src-templates-tb-post-area-js" */),
  "component---src-templates-tb-post-js": () => import("./../../../src/templates/TbPost.js" /* webpackChunkName: "component---src-templates-tb-post-js" */),
  "component---src-templates-tb-product-category-js": () => import("./../../../src/templates/TbProductCategory.js" /* webpackChunkName: "component---src-templates-tb-product-category-js" */),
  "component---src-templates-tb-product-js": () => import("./../../../src/templates/TbProduct.js" /* webpackChunkName: "component---src-templates-tb-product-js" */)
}

